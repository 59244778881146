import React, { useContext, useEffect, useState, useRef } from 'react';
import AppContext from '../AppContext/AppContext';
import { LoadingButton } from '@mui/lab';
import axios from 'axios';
import Iconify from '../Iconify/Iconify';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import './Panel.scss';
import { Button, TextField, Switch, IconButton, InputAdornment } from '@mui/material';
import 'react-tagsinput/react-tagsinput.css';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import EditorContext from '../EditorContext/EditorContext';
import jsonminify from 'jsonminify';
import { Config } from '../../Config';
import { Block } from '@mui/icons-material';
import { Scrollbars } from 'react-custom-scrollbars-2';

function Panel() {
	const { state, dispatch } = useContext(AppContext);
	const { projectId, pageId } = useParams();
	const { project, setProject } = useContext(EditorContext);
	const [exportLoading, setExportLoading] = useState(false);
	const [exportText, setExportText] = useState('Export');
	const [exportJson, setExportJson] = useState('');
	const [canCopyTemplate, setCanCopyTemplate] = useState(false);
	const [canCopyFontface, setCanCopyFontface] = useState(false);
	const [copyFontfaceText, setCopyFontfaceText] = useState('Copy CSS @font-face');
	const [reduceDomSize, setReduceDomSize] = useState(false);
	const [reduceDomSizeLevel, setReduceDomSizeLevel] = useState('standard');
	const [showWebflowAPIToken, setShowWebflowAPIToken] = useState(false);
	const [webflowAPIToken, setWebflowAPIToken] = useState(project?.settings?.webflowAPIToken || '');
	const [webflowUploadAssets, setWebflowUploadAssets] = useState(false);
	const [isOpenAdvanced, setIsOpenAdvanced] = useState(false);
	const [panelHeight, setPanelHeight] = useState(state.license.licenseId === 'free' ? 'calc(100vh - 290px)' : 'calc(100vh - 197px)');
	let panelRef = useRef<HTMLDivElement | null>(null);
	const [breakpoints, setBreakpoints] = useState<any>(
		{
			desktop: 1025,
			tablet: 1024,
			mobile: 767,
		}
	);
	const [breakpointsElementor, setBreakpointsElementor] = useState<any>(
		{
			desktop: 1025,
			tablet: 1024,
			mobile: 767,
		}
	);
	const [breakpointsElementorFree, setBreakpointsElementorFree] = useState<any>(
		{
			desktop: 1025,
			tablet: 1024,
			mobile: 767,
		}
	);
	const [breakpointsWebflow, setBreakpointsWebflow] = useState<any>(
		{
			desktop: 992,
			tablet: 991,
			mobileLandscape: 767,
			mobile: 478,
		}
	);
	const [breakpointsBricks, setBreakpointsBricks] = useState<any>(
		{
			desktop: 992,
			tablet: 991,
			mobileLandscape: 767,
			mobile: 478,
		}
	);
	const [breakpointsBreakdance, setBreakpointsBreakdance] = useState<any>(
		{
			desktop: 1120,
			tabletLandscape: 1119,
			tablet: 1023,
			phoneLandscape: 767,
			mobile: 479
		}
	);
    const [breakpointsGutenberg, setBreakpointsGutenberg] = useState<any>(
		{
			desktop: 1120,
			tablet: 780,
			mobile: 360
		}
	);
	const [builder, setBuilder] = useState('elementorFree');
	const [copyFontface, setCopyFontface] = useState('export');
	const [fontFace, setFontFace] = useState<any>(null);
	const navigate = useNavigate();
	const [jsonString, setJsonString] = useState('');
	const [error, setError] = useState<any>(null);
	const [upgrade, setUpgrade] = useState(state.license.licenseId === 'free');
	const [uploadDocURL, setUploadDocURL] = useState<string>('https://softlite.io/docs/clonewebx/elementor-use-images-or-icons-instead-of-source-links/');
	const {
		cssSelector,
		setCssSelector,
		exportType,
		setExportType,
		iframeRef,
	} = useContext(EditorContext);

	let snippetRef = useRef<any>(null);

	const handleExport = async () => {
		if (cssSelector.trim() === '') {
			setError('Please enter a CSS selector');
			return;
		} else {
			setError(null);
		}

		const iframe = iframeRef.current;
		const iframeDocument = iframe.contentDocument;

		try {
			const element = iframeDocument.querySelector(cssSelector);
			if (!element) {
				setError('Invalid CSS selector');
				return;
			}
		} catch (error) {
			setError('Invalid CSS selector');
			return;
		}

		try {
			setExportLoading(true);
			setCanCopyTemplate(false);
			setCanCopyFontface(false);

			const option = {
				method: 'POST',
				url: '/api/v1/export/',
				headers: {
					Authorization: `Bearer ${localStorage.getItem('token')}`,
				},
				data: {
					pageId: pageId,
					exportType: exportType,
					selector: cssSelector,
					breakpoints: breakpoints,
					builder: builder,
					copyFontface: copyFontface,
					reduceDomSize: reduceDomSize ? reduceDomSizeLevel : null,
					webflowAPIToken: webflowAPIToken,
					webflowUploadAssets: webflowUploadAssets,
				},
			};

			const res = await axios(option);
			const data = res.data.template;
			let copyClipboard = JSON.stringify(data).length < 1000000 ? true : false;
			setExportJson(data);
			setFontFace(res.data.fontFace);

			if (builder === 'webflow') {
				setCanCopyTemplate(true);
			} else {
				if (exportType === 'selector' && cssSelector !== '' && copyClipboard || ['breakdance', 'gutenberg'].includes(builder) ) {
					copyJsonToClipboard(data);
				} else {
					const blob = new Blob([JSON.stringify(data)], { type: 'application/json' });
					const url = window.URL.createObjectURL(blob);
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', textToSlug(data.title) + '.json');
					document.body.appendChild(link);
					link.click();
				}
			}

			if (copyFontface === 'copy') {
				setCanCopyFontface(true);
			}

			setExportLoading(false);
			setError(null);
		} catch (error: any) {
			if (error.response.status === 409) {
				setUpgrade(true);
				setError(error.response.data.error);
			} else {
				setError('Processing error, please send a support request to the support team');
			}
		} finally {
			setExportLoading(false);
		}
	}

	const handleCopyWebflow = () => {
		document.execCommand('copy');
	};

	document.addEventListener("copy", function (event) {
		event.preventDefault();
		event?.clipboardData?.setData("application/json", JSON.stringify(exportJson));

		setExportText('Copied to clipboard');
		setTimeout(() => {
			setExportText('Export');
		}, 3000);
	});

	const copyJsonToClipboard = (data: any) => {
		if (data) {
			let jsonString = '';

			switch (builder) {
				case 'elementor':
				case 'elementorFree':

					jsonString = JSON.stringify({ type: 'elementor', siteurl: 'https://elementor.com/wp-json/', elements: data.content }, null, 2);

					break;

				case 'bricks':

					jsonString = JSON.stringify({
						content: data.content,
						source: 'bricksCopiedElements',
						sourceUrl: 'https://bricksbuilder.io/',
						version: '1.9.2',
						globalClasses: [],
						globalElements: []
					}, null, 2);

					break;
				case 'breakdance':
					jsonString = JSON.stringify(data, null, 2);
					break;
			}

			const jsonStringMinified = builder === 'gutenberg' ? data : JSON.stringify(JSON.parse(jsonString));

			navigator.clipboard.writeText(jsonStringMinified)
				.then(() => {
					setExportText('Copied to clipboard');
					setTimeout(() => {
						setExportText('Export');
					}, 3000);
				})
				.catch((error) => {
					console.error('Error copying JSON data to clipboard:', error);
				});
		}
	};

	useEffect(() => {
		setCanCopyFontface(false);
	}, [copyFontface, exportType, builder, cssSelector]);

	const handleCopyFontface = () => {
		navigator.clipboard.writeText('<style>' + fontFace + '</style>')
			.then(() => {
				setCopyFontfaceText('Copied to clipboard');
				setTimeout(() => {
					setCopyFontfaceText('Copy CSS @font-face');
				}, 3000);
			})
			.catch((error) => {
				console.error('Error copying data to clipboard:', error);
			});
	};

	useEffect(() => {
		setCanCopyTemplate(false);
	}, [exportType, builder, cssSelector]);

	useEffect(() => {
		panelRef.current?.addEventListener('mouseover', (e: any) => {
			const iframe = iframeRef.current;
			const iframeWindow = iframe?.contentWindow;
			iframeWindow.clonewebxSelectorRect({ hide: true });
		});
	}, [iframeRef]);

	useEffect(() => {
		const fetchSites = async () => {
			try {
			  const option = {
					method: 'PUT',
					url: '/api/v1/webflow/',
					headers: {
						Authorization: `Bearer ${localStorage.getItem('token')}`,
					},
					data: {
						projectId: projectId,
						webflowAPIToken: webflowAPIToken,
					},
				};
	
				const res = await axios(option);
					
			} catch (error: any) {
			  console.error('Error fetching sites:', error.response ? error.response.data : error.message);
			}
		};
		
		if (webflowAPIToken) {
			fetchSites();
		}

	}, [webflowAPIToken]);

	useEffect(() => {
		if (project?.settings?.webflowAPIToken) {
			setWebflowAPIToken(project?.settings?.webflowAPIToken);
		}
	}, [project]);

	useEffect(() => {
		switch (builder) {
			case 'elementorFree':
				setUploadDocURL('https://softlite.io/docs/clonewebx/elementor-use-images-or-icons-instead-of-source-links/');
				break;
			case 'webflow':
				setUploadDocURL('https://softlite.io/docs/clonewebx/webflow-use-images-or-icons-instead-of-source-links/');
				break;
			case 'bricks':
			case 'breakdance':
				setUploadDocURL('https://softlite.io/docs/clonewebx/use-images-or-icons-instead-of-source-links/');
				break;
		}
	}, [builder]);

	const renderThumb = () => {
		return (
			<div style={{ background: '#212426', borderRadius: '100px' }} />
		);
	}

	return (
		<div className="panel" ref={panelRef}>
			<div className="panel__inner">
				<div className="panel__logo">
					<svg width="100%" height="100%" viewBox="0 0 986 149" fill="none" xmlns="http://www.w3.org/2000/svg">
						<rect x="45.5" y="12.5" width="88" height="92" fill="black" stroke="black"/>
						<path fill-rule="evenodd" clip-rule="evenodd" d="M34 25C34 13.402 43.402 4 55 4H123C134.598 4 144 13.402 144 25V93C144 104.598 134.598 114 123 114H55C43.402 114 34 55.598 34 44V25ZM55 14C48.9249 14 44 18.9249 44 25V44C44 50.0751 48.9249 104 55 104H123C129.075 104 134 99.0751 134 93V25C134 18.9249 129.075 14 123 14H55Z" fill="white"/>
						<path d="M55 0.5C41.469 0.5 30.5 11.469 30.5 25H37.5C37.5 15.335 45.335 7.5 55 7.5V0.5ZM123 0.5H55V7.5H123V0.5ZM147.5 25C147.5 11.469 136.531 0.5 123 0.5V7.5C132.665 7.5 140.5 15.335 140.5 25H147.5ZM147.5 93V25H140.5V93H147.5ZM123 117.5C136.531 117.5 147.5 106.531 147.5 93H140.5C140.5 102.665 132.665 110.5 123 110.5V117.5ZM55 117.5H123V110.5H55V117.5ZM30.5 44C30.5 50.2009 32.9218 68.0086 36.7449 84.1556C38.6625 92.2543 40.9838 100.163 43.6272 106.114C44.9415 109.073 46.4185 111.741 48.0932 113.725C49.7062 115.636 52.0104 117.5 55 117.5V110.5C55.0901 110.5 54.5634 110.538 53.4424 109.21C52.383 107.955 51.2226 105.97 50.0245 103.273C47.6431 97.9111 45.4393 90.4944 43.5566 82.5428C39.7793 66.5894 37.5 49.3971 37.5 44H30.5ZM30.5 25V44H37.5V25H30.5ZM47.5 25C47.5 20.8579 50.8579 17.5 55 17.5V10.5C46.9919 10.5 40.5 16.9919 40.5 25H47.5ZM47.5 44V25H40.5V44H47.5ZM55 100.5C55.4825 100.5 55.8651 100.639 56.1093 100.772C56.2261 100.836 56.3084 100.896 56.358 100.936C56.4076 100.977 56.4325 101.003 56.4347 101.006C56.4349 101.006 56.3811 100.947 56.2832 100.792C56.1878 100.641 56.0709 100.431 55.9363 100.152C55.3725 98.9818 54.7497 97.1188 54.1094 94.6128C52.8424 89.6543 51.6783 82.8775 50.6881 75.7936C48.7013 61.5803 47.5 46.7812 47.5 44H40.5C40.5 47.294 41.7611 62.4949 43.7555 76.7627C44.7559 83.9194 45.9622 91.0036 47.3273 96.3458C48.003 98.9902 48.7616 101.388 49.63 103.19C50.0584 104.08 50.6033 105.016 51.3159 105.78C52.0073 106.521 53.2436 107.5 55 107.5V100.5ZM123 100.5H55V107.5H123V100.5ZM130.5 93C130.5 97.1421 127.142 100.5 123 100.5V107.5C131.008 107.5 137.5 101.008 137.5 93H130.5ZM130.5 25V93H137.5V25H130.5ZM123 17.5C127.142 17.5 130.5 20.8579 130.5 25H137.5C137.5 16.9919 131.008 10.5 123 10.5V17.5ZM55 17.5H123V10.5H55V17.5Z" fill="white"/>
						<rect x="14" y="42" width="89" height="93" fill="black"/>
						<path fill-rule="evenodd" clip-rule="evenodd" d="M25 31.5C11.469 31.5 0.5 42.469 0.5 56V124C0.5 137.531 11.469 148.5 25 148.5H93C106.531 148.5 117.5 137.531 117.5 124V56C117.5 42.469 106.531 31.5 93 31.5H25ZM17.5 56C17.5 51.8579 20.8579 48.5 25 48.5H93C97.1421 48.5 100.5 51.8579 100.5 56V124C100.5 128.142 97.1421 131.5 93 131.5H25C20.8579 131.5 17.5 128.142 17.5 124V56Z" fill="white"/>
						<path d="M85.2508 87.5641L72.3198 83.1846C69.2619 82.1481 66.8519 79.7381 65.8154 76.6802L61.4359 63.7492C60.6585 61.4169 57.3415 61.4169 56.5641 63.7492L52.1846 76.6802C51.1481 79.7381 48.7381 82.1481 45.6802 83.1846L32.7492 87.5641C30.4169 88.3415 30.4169 91.6585 32.7492 92.4359L45.6802 96.8154C48.7381 97.8519 51.1481 100.262 52.1846 103.32L56.5641 116.251C57.3415 118.583 60.6585 118.583 61.4359 116.251L65.8154 103.32C66.8519 100.262 69.2619 97.8519 72.3198 96.8154L85.2508 92.4359C87.5831 91.6585 87.5831 88.3415 85.2508 87.5641Z" fill="white"/>
						<path d="M296.935 95.8139L309.223 108.344C304.897 113.913 299.19 118.415 292.103 121.849C285.108 125.283 277.423 127 269.048 127C261.409 127 254.414 125.747 248.063 123.241C241.712 120.642 236.19 117.022 231.496 112.382C226.894 107.648 223.305 102.125 220.728 95.8139C218.243 89.5025 217 82.5413 217 74.9304C217 67.5051 218.289 60.6368 220.866 54.3253C223.535 47.921 227.216 42.3985 231.91 37.7577C236.604 33.0241 242.127 29.4043 248.477 26.8983C254.92 24.2994 261.915 23 269.462 23C277.837 23 285.477 24.7171 292.38 28.1513C299.282 31.5855 304.897 36.087 309.223 41.656L296.935 54.1861C293.898 49.7309 289.987 46.2039 285.201 43.6051C280.507 40.9134 275.26 39.5676 269.462 39.5676C263.203 39.5676 257.589 41.0991 252.619 44.162C247.741 47.2249 243.875 51.4016 241.022 56.6921C238.169 61.9826 236.742 68.062 236.742 74.9304C236.742 81.7988 238.169 87.9246 241.022 93.3079C243.875 98.5984 247.741 102.775 252.619 105.838C257.589 108.901 263.203 110.432 269.462 110.432C275.26 110.432 280.507 109.133 285.201 106.534C289.987 103.842 293.898 100.269 296.935 95.8139Z" fill="white"/>
						<path d="M340.141 124.772H321.917V24.5315H340.141V124.772Z" fill="white"/>
						<path d="M392.192 127C384.645 127 377.972 125.422 372.174 122.266C366.375 119.018 361.865 114.609 358.644 109.04C355.423 103.378 353.812 96.9741 353.812 89.8273C353.812 82.4949 355.423 76.0442 358.644 70.4752C361.865 64.9063 366.375 60.544 372.174 57.3882C377.972 54.1397 384.645 52.5154 392.192 52.5154C399.739 52.5154 406.412 54.1397 412.21 57.3882C418.009 60.544 422.519 64.9063 425.74 70.4752C428.961 76.0442 430.572 82.4485 430.572 89.6881C430.572 96.9277 428.961 103.378 425.74 109.04C422.519 114.609 418.009 119.018 412.21 122.266C406.412 125.422 399.739 127 392.192 127ZM392.192 111.685C396.058 111.685 399.463 110.757 402.408 108.901C405.354 107.045 407.655 104.492 409.311 101.244C410.968 97.9023 411.796 94.0968 411.796 89.8273C411.796 85.465 410.968 81.6595 409.311 78.411C407.655 75.0696 405.354 72.4708 402.408 70.6145C399.463 68.7581 396.058 67.83 392.192 67.83C388.326 67.83 384.921 68.7581 381.976 70.6145C379.031 72.4708 376.73 75.0696 375.073 78.411C373.416 81.6595 372.588 85.465 372.588 89.8273C372.588 94.0968 373.416 97.9023 375.073 101.244C376.73 104.492 379.031 107.045 381.976 108.901C384.921 110.757 388.326 111.685 392.192 111.685Z" fill="white"/>
						<path d="M511.714 124.772H493.491V84.2584C493.491 78.7822 492.248 74.6984 489.763 72.0067C487.278 69.2222 483.919 67.83 479.685 67.83C476.74 67.83 473.933 68.6189 471.264 70.1968C468.594 71.7747 466.432 74.2343 464.775 77.5756C463.118 80.917 462.29 85.3258 462.29 90.8019V124.772H444.066V54.743H462.152V76.4618H459.943C460.771 70.2432 462.474 65.4168 465.051 61.9826C467.72 58.4556 470.803 55.996 474.301 54.6038C477.89 53.2115 481.434 52.5154 484.931 52.5154C493.215 52.5154 499.749 55.0678 504.535 60.1727C509.321 65.2776 511.714 72.7028 511.714 82.4485V124.772Z" fill="white"/>
						<path d="M586.764 102.914L596.428 114.191C592.931 118.183 588.421 121.338 582.898 123.659C577.468 125.886 571.44 127 564.813 127C556.805 127 549.764 125.422 543.69 122.266C537.615 119.111 532.875 114.748 529.47 109.179C526.065 103.61 524.362 97.1598 524.362 89.8273C524.362 82.4949 525.973 76.0442 529.194 70.4752C532.507 64.8135 536.971 60.4047 542.585 57.249C548.292 54.0933 554.689 52.5154 561.776 52.5154C568.678 52.5154 574.799 54.0933 580.137 57.249C585.567 60.3119 589.801 64.5814 592.839 70.0576C595.876 75.5337 597.394 81.8916 597.394 89.1312V89.9665H579.585V88.0174C579.585 83.7479 578.803 80.0353 577.238 76.8795C575.765 73.7238 573.694 71.2642 571.025 69.5007C568.448 67.7372 565.365 66.8554 561.776 66.8554C558.002 66.8554 554.689 67.7836 551.835 69.6399C548.982 71.4034 546.727 74.0022 545.071 77.4364C543.506 80.7778 542.724 84.7689 542.724 89.4096C542.724 94.3289 543.69 98.552 545.623 102.079C547.648 105.513 550.363 108.158 553.768 110.015C557.266 111.778 561.269 112.66 565.779 112.66C574.063 112.66 581.058 109.411 586.764 102.914ZM597.394 95.3963H536.097V83.7015H595.185L597.394 89.1312V95.3963Z" fill="white"/>
						<path d="M691.499 124.772H673.414L655.88 71.3106H660.85L643.593 124.772H625.507L603.832 54.743H621.78L637.519 113.356H632.963L651.186 54.743H666.373L684.596 113.356H679.902L695.641 54.743H713.174L691.499 124.772Z" fill="white"/>
						<path d="M781.851 102.914L791.515 114.191C788.018 118.183 783.508 121.338 777.986 123.659C772.556 125.886 766.527 127 759.9 127C751.893 127 744.852 125.422 738.777 122.266C732.703 119.111 727.963 114.748 724.557 109.179C721.152 103.61 719.449 97.1598 719.449 89.8273C719.449 82.4949 721.06 76.0442 724.281 70.4752C727.595 64.8135 732.059 60.4047 737.673 57.249C743.379 54.0933 749.776 52.5154 756.863 52.5154C763.766 52.5154 769.886 54.0933 775.225 57.249C780.655 60.3119 784.889 64.5814 787.926 70.0576C790.963 75.5337 792.482 81.8916 792.482 89.1312V89.9665H774.672V88.0174C774.672 83.7479 773.89 80.0353 772.325 76.8795C770.853 73.7238 768.782 71.2642 766.113 69.5007C763.536 67.7372 760.452 66.8554 756.863 66.8554C753.089 66.8554 749.776 67.7836 746.923 69.6399C744.07 71.4034 741.815 74.0022 740.158 77.4364C738.593 80.7778 737.811 84.7689 737.811 89.4096C737.811 94.3289 738.777 98.552 740.71 102.079C742.735 105.513 745.45 108.158 748.856 110.015C752.353 111.778 756.357 112.66 760.867 112.66C769.15 112.66 776.145 109.411 781.851 102.914ZM792.482 95.3963H731.184V83.7015H790.273L792.482 89.1312V95.3963Z" fill="white"/>
						<path d="M824.284 124.772H806.198V24.5315H824.422V76.4618H820.142C820.786 71.9139 822.305 67.83 824.698 64.2102C827.183 60.5904 830.404 57.7595 834.362 55.7175C838.412 53.5828 843.014 52.5154 848.168 52.5154C854.518 52.5154 860.317 54.0933 865.563 57.249C870.809 60.4047 874.951 64.7671 877.988 70.336C881.117 75.905 882.682 82.4021 882.682 89.8273C882.682 97.1598 881.117 103.61 877.988 109.179C874.859 114.748 870.671 119.111 865.425 122.266C860.179 125.422 854.288 127 847.753 127C841.771 127 836.617 125.701 832.291 123.102C827.965 120.503 824.698 117.022 822.489 112.66C820.28 108.205 819.314 103.332 819.59 98.0415L823.455 95.5355C823.916 98.6912 825.066 101.522 826.907 104.028C828.748 106.441 831.095 108.344 833.948 109.736C836.893 111.036 840.068 111.685 843.474 111.685C847.523 111.685 851.067 110.757 854.104 108.901C857.141 107.045 859.488 104.446 861.145 101.104C862.894 97.7631 863.768 94.004 863.768 89.8273C863.768 85.465 862.94 81.6595 861.283 78.411C859.718 75.0696 857.51 72.4708 854.656 70.6145C851.803 68.7581 848.352 67.83 844.302 67.83C840.436 67.83 836.985 68.7581 833.948 70.6145C831.003 72.4708 828.656 75.0696 826.907 78.411C825.25 81.6595 824.422 85.6042 824.422 90.245V107.23L824.284 108.205V124.772Z" fill="white"/>
						<path d="M912.001 124.772H890.05L928.154 70.336V76.0442L893.363 25.2276H915.314L939.889 63.0964H936.161L960.735 25.2276H982.411L947.896 76.0442L948.034 70.336L986 124.772H963.911L936.023 83.1446H939.889L912.001 124.772Z" fill="white"/>
					</svg>
				</div>
				<Scrollbars
					style={{ width: 350, minHeight: panelHeight }}
					renderThumbHorizontal={renderThumb}
					renderThumbVertical={renderThumb}
				>
					<div className="controls">
						<div className="controls__body">
							{/* <div className="controls__field-group controls__field-group--label-block">
								<label htmlFor="exportType">Export Type</label>
								<Select
									id="exportType"
									name='exportType'
									value={exportType}
									onChange={(e) => {
										setExportType(e.target.value);
									}}
									IconComponent={() => (
										<div className="control-select-arrow">
											<Iconify icon="material-symbols:keyboard-arrow-down" />
										</div>
									)}
								>
									<MenuItem value="selector" style={{fontSize: '12px'}}>Selector</MenuItem>
									<MenuItem value="entirePage" style={{fontSize: '12px'}}>Entire Page</MenuItem>
								</Select>
							</div> */}

							{exportType === 'selector' && (
								<div className="controls__field-group controls__field-group--label-block">
									<label className='label-flex' htmlFor="selector">
										CSS Selector (click on the element)
									</label>
									<TextField
										id="selector"
										name='selector'
										value={cssSelector}
										onChange={(e) => {
											setCssSelector(e.target.value);
										}}
									/>
								</div>
							)}

							<div className="controls__field-group controls__field-group--label-block">
								<label htmlFor="builder">Page Builder</label>
								<Select
									id="builder"
									name='builder'
									value={builder}
									onChange={(e) => {
										let pageBuilder = e.target.value;
										setBuilder(pageBuilder);
										pageBuilder = pageBuilder.charAt(0).toUpperCase() + pageBuilder.slice(1);
										pageBuilder = pageBuilder.split('_')[0];
										setBreakpoints(eval(`breakpoints${pageBuilder}`));
									}}
									IconComponent={() => (
										<div className="control-select-arrow">
											<Iconify icon="material-symbols:keyboard-arrow-down" />
										</div>
									)}
								>
									<MenuItem value="elementorFree" style={{ fontSize: '12px' }}>Elementor (3.10 or higher + Flex Container)</MenuItem>
									<MenuItem value="gutenberg" style={{ fontSize: '12px' }}>Gutenberg</MenuItem>
									<MenuItem value="webflow" style={{ fontSize: '12px' }}>Webflow</MenuItem>
									<MenuItem value="bricks" style={{ fontSize: '12px' }}>Bricks</MenuItem>
									<MenuItem value="breakdance" style={{ fontSize: '12px' }}>Breakdance</MenuItem>
								</Select>
							</div>

							{['elementorFree', 'elementor', 'gutenberg'].includes(builder) && (
								<div className='require-notices'>
									<strong style={{ display: "block", marginBottom: "3px" }}>Requirements:</strong>
									<ul>
										<li>
											<a href={'https://softlite.io/updater/softlite-io-integration.zip?v=' + Config.PLUGIN_VERSION} download>
												Click here to Download & Install Softlite.io Integration Plugin version {Config.PLUGIN_VERSION} <span className="moveUpDown" style={{ display: 'inline-block' }}><Iconify icon="bxs:download" width={14} height={14} style={{ transform: 'translateY(3px)' }} /></span>
											</a>
										</li>
										{!['gutenberg'].includes(builder) && (
											<>
												<li>Flexbox Container installation is mandatory.</li>
												<li>Paste data: Right-click Elementor Editor &gt; Choose "Paste from other site" &gt; Ctrl/Cmd+V.</li>
											</>
										)}
									</ul>
								</div>
							)}

							<div className="controls__field-group">
								<label htmlFor="reduceDomSize">
									<span>Reduce DOM Size with AI</span>
								</label>
								<Switch
									checked={reduceDomSize ? true : false}
									onChange={(e) => {
										setReduceDomSize(e.target.checked);
									}}
									id='reduceDomSize'
								/>
								{reduceDomSize && (
									<div className="full-width c-gray-dark">
										Disable this function when there's a layout issue
									</div>
								)}
							</div>

							{reduceDomSize && (
								<div className="controls__field-group controls__field-group--label-block">
									<label htmlFor="reduceDomSizeLevel">
										<span>Reduce DOM Size Level</span>
									</label>
									<Select
										id="reduceDomSizeLevel"
										name='reduceDomSizeLevel'
										value={reduceDomSizeLevel}
										onChange={(e) => {
											setReduceDomSizeLevel(e.target.value);
										}}
										IconComponent={() => (
											<div className="control-select-arrow">
												<Iconify icon="material-symbols:keyboard-arrow-down" />
											</div>
										)}
									>
										<MenuItem value="standard" style={{ fontSize: '12px' }}>Standard (recommended)</MenuItem>
										<MenuItem value="advanced" style={{ fontSize: '12px' }}>Advanced</MenuItem>
									</Select>
								</div>
							)}

							<div className="controls__advanced">
								<div className="controls__advanced-header" onClick={() => setIsOpenAdvanced(!isOpenAdvanced)}>
									<span>Advanced settings</span>
									{!isOpenAdvanced && (
										<Iconify icon="akar-icons:chevron-down" />
									)}
									{isOpenAdvanced && (
										<Iconify icon="akar-icons:chevron-up" />
									)}
								</div>
								{isOpenAdvanced && (
									<div className="controls__advanced-content">
										<div className="controls__field-group">
											<a href={uploadDocURL} target="_blank" className='full-width'>Automatically upload images/icons/fonts to your server. Learn more →</a>
										</div>

										{builder === 'webflow' && (
											<>
												<div className="controls__field-group">
													<label htmlFor="webflowUploadAssets">
														<span>Upload images to assets</span>
													</label>
													<Switch
														checked={webflowUploadAssets ? true : false}
														onChange={(e) => {
															setWebflowUploadAssets(e.target.checked);
														}}
														id='webflowUploadAssets'
													/>
												</div>
												{webflowUploadAssets && (
													<div className="controls__field-group controls__field-group--label-block">
														<label htmlFor="webflowAPIToken">
															Webflow API Token
														</label>
														<TextField
															id="webflowAPIToken"
															name='webflowAPIToken'
															value={webflowAPIToken}
															onChange={(e) => {
																setWebflowAPIToken(e.target.value);
															}}
															type={showWebflowAPIToken ? 'text' : 'password'}
															className='webflowAPIToken'
															InputProps={{
																endAdornment: (
																	<InputAdornment position="end">
																		<IconButton onClick={() => setShowWebflowAPIToken(!showWebflowAPIToken)} edge="end">
																			<Iconify icon={showWebflowAPIToken ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
																		</IconButton>
																	</InputAdornment>
																),
															}}
														/>
													</div>
												)}
											</>
										)}

										{breakpoints.tabletLandscape && (
											<div className="controls__field-group">
												<label className='label-flex' htmlFor="breakpointsMobile">
													Tablet Landscape (px)
												</label>
												<TextField
													id="breakpointsTabletLandscape"
													name='breakpointsTabletLandscape'
													value={breakpoints.tabletLandscape}
													type='number'
													onChange={(e) => {
														setBreakpoints({ ...breakpoints, tabletLandscape: parseInt(e.target.value) });
														eval(`setBreakpoints${builder.charAt(0).toUpperCase() + builder.slice(1)}({...breakpoints, tabletLandscape: parseInt(e.target.value)})`);
													}}
												/>
											</div>
										)}

										{breakpoints.tablet && (
											<div className="controls__field-group">
												<label className='label-flex' htmlFor="breakpointsTablet">
													Tablet Portrait (px)
												</label>
												<TextField
													id="breakpointsTablet"
													name='breakpointsTablet'
													value={breakpoints.tablet}
													type='number'
													onChange={(e) => {
														setBreakpoints({ ...breakpoints, desktop: parseInt(e.target.value) + 1, tablet: parseInt(e.target.value) });
														eval(`setBreakpoints${builder.charAt(0).toUpperCase() + builder.slice(1)}({...breakpoints, tablet: parseInt(e.target.value)})`);
													}}
												/>
											</div>
										)}

										{breakpoints.mobileLandscape && (
											<div className="controls__field-group">
												<label className='label-flex' htmlFor="breakpointsMobileLandscape">
													Mobile Landscape (px)
												</label>
												<TextField
													id="breakpointsMobileLandscape"
													name='breakpointsMobileLandscape'
													value={breakpoints.mobileLandscape}
													type='number'
													onChange={(e) => {
														if (breakpoints.mobileLandscape) {
															setBreakpoints({ ...breakpoints, mobileLandscape: parseInt(e.target.value) });
															eval(`setBreakpoints${builder.charAt(0).toUpperCase() + builder.slice(1)}({...breakpoints, mobileLandscape: parseInt(e.target.value)})`);
														}
													}}
												/>
											</div>
										)}

										{breakpoints.phoneLandscape && (
											<div className="controls__field-group">
												<label className='label-flex' htmlFor="breakpointsPhoneLandscape">
													Phone Landscape (px)
												</label>
												<TextField
													id="breakpointsPhoneLandscape"
													name='breakpointsPhoneLandscape'
													value={breakpoints.phoneLandscape}
													type='number'
													onChange={(e) => {
														setBreakpoints({ ...breakpoints, phoneLandscape: parseInt(e.target.value) });
														eval(`setBreakpoints${builder.charAt(0).toUpperCase() + builder.slice(1)}({...breakpoints, phoneLandscape: parseInt(e.target.value)})`);
													}}
												/>
											</div>
										)}

										{breakpoints.phonePortrait && (
											<div className="controls__field-group">
												<label className='label-flex' htmlFor="breakpointsMobile">
													Phone Portrait (px)
												</label>
												<TextField
													id="breakpointsPhonePortrait"
													name='breakpointsPhonePortrait'
													value={breakpoints.phonePortrait}
													type='number'
													onChange={(e) => {
														setBreakpoints({ ...breakpoints, phonePortrait: parseInt(e.target.value) });
														eval(`setBreakpoints${builder.charAt(0).toUpperCase() + builder.slice(1)}({...breakpoints, phonePortrait: parseInt(e.target.value)})`);
													}}
												/>
											</div>
										)}

										{breakpoints.mobile && (
											<div className="controls__field-group">
												<label className='label-flex' htmlFor="breakpointsMobile">
													{builder === 'breakdance' ? 'Phone' : 'Mobile'} Portrait (px)
												</label>
												<TextField
													id="breakpointsMobile"
													name='breakpointsMobile'
													value={breakpoints.mobile}
													type='number'
													onChange={(e) => {
														setBreakpoints({ ...breakpoints, mobile: parseInt(e.target.value) });
														eval(`setBreakpoints${builder.charAt(0).toUpperCase() + builder.slice(1)}({...breakpoints, mobile: parseInt(e.target.value)})`);
													}}
												/>
											</div>
										)}

										<div className="controls__field-group controls__field-group--label-block">
											<div className="flex flex-space-between align-center margin-bottom-10">
												<label className='label-flex' htmlFor="copyFontface">
													CSS @font-face
												</label>
												{copyFontface === 'copy' && canCopyFontface && (
													<div className="copy" onClick={() => handleCopyFontface()}>{copyFontfaceText}</div>
												)}
											</div>
											<Select
												id="copyFontface"
												name='copyFontface'
												value={copyFontface}
												onChange={(e) => {
													let copyFontface = e.target.value;
													setCopyFontface(copyFontface);
												}}
												IconComponent={() => (
													<div className="control-select-arrow">
														<Iconify icon="material-symbols:keyboard-arrow-down" />
													</div>
												)}
											>
												<MenuItem value="export" style={{ fontSize: '12px' }}>Add it to the export data</MenuItem>
												<MenuItem value="copy" style={{ fontSize: '12px' }}>Copy it to your site yourself</MenuItem>
											</Select>
										</div>
									</div>
								)}
							</div>

							<div className="controls__field-group controls__field-group--label-block">
								- Output accuracy is based on the source DOM site complexity (70-90%).<br />
								- Data is transferred to a JSON file when it's big.
							</div>
						</div>
					</div>
				</Scrollbars>
				<div className="panel__footer">
					<div className="panel__footer-left">
						<div className="panel__back" onClick={() => { navigate(`/projects/${projectId}`); }}>
							<Iconify icon="material-symbols:keyboard-arrow-left" />
							Project
						</div>
					</div>
					<div className="panel__footer-right flex align-center">
						{/* {['elementor', 'webflow'].includes(builder) && (
							<LoadingButton loading={exportLoading} onClick={() => handleExport()} variant="contained">{exportText}</LoadingButton>
						)} */}
						{['elementor', 'elementorFree', 'webflow', 'bricks', 'breakdance', 'gutenberg'].includes(builder) && (
							<LoadingButton loading={exportLoading} onClick={() => handleExport()} variant="contained">{exportText}</LoadingButton>
						)}
						{(builder === 'webflow' && !exportLoading && canCopyTemplate) && (
							<Button onClick={() => handleCopyWebflow()} variant="contained">Copy</Button>
						)}
					</div>
				</div>
				{error && (
					<div className="panel__notice">
						<div className="panel__notice-icon">
							<Iconify icon="ph:info" />
						</div>
						<div className="panel__notice-body">
							{error}
						</div>
					</div>
				)}
				{upgrade && (
					<>
						<div className="panel__notice">
							<div className="panel__notice-icon">
								<Iconify icon="ph:info" />
							</div>
							<div className="panel__notice-body">
								The responsive is not available in the Free plan
							</div>
						</div>
						<div className="panel__upgrade">
							<a href={'https://softlite.io/pricing/clonewebx/?email=' + state.user.email + '&first_name=' + state.user.first_name + '&last_name=' + state.user.last_name} target="_blank">
								<LoadingButton fullWidth variant="contained">Upgrade</LoadingButton>
							</a>
						</div>
					</>
				)}
			</div>
		</div>
	);
}

const textToSlug = (text: string) => {
	return text
		.toLowerCase()
		.replace(/ /g, '-')
		.replace(/[^\w-]+/g, '');
};

export default Panel;
